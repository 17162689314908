import { useSelector } from "react-redux"
import config from "../../data/config.json"
import { truncateText } from "../../utils/uiUtils"

export default function Header() {
    const address = useSelector((state) => state.auth.address)

    return (
        <>
            {config.logo && <img alt="logo" className="dAppLogo" src={config.logo} />}
            {config.title && <h2 className="dAppTitle">{config.title}</h2>}
            {config.description && <p className="dAppDescription">{config.description}</p>}
            {address && <p className="address">{truncateText(address, 5, 5)}</p>}
        </>
    )
}
