import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import { setLoading } from "../actions/authActions"
import {
    calculateRewards,
    checkIfCanClaim,
    checkIfUserIsStaking,
    claimOnlyRewards,
    claimStakingRewards,
    daysUntilClaim,
    getStakeTokenBalance,
    getTotalStaked,
    hasEnoughTokenBalance,
    listBrackets,
    stakeAmount,
    userBracket
} from "../controllers/wallet"
import "./css/staking.css"
import Footer from "./global/footer"
import Header from "./global/header"
import Watermark from "./home/watermark"
import "./main-layout.css"

const PRECISION = 10000
const BILLION = 1000000000

function formatBalance(balance) {
    if (balance > 0) return balance.toLocaleString()
    else return 0
}

function formatAPY(apy) {
    return apy.toFixed(3)
}

async function stake(dispatch, userWallet, amount, bracketSelectedIndex) {
    if (bracketSelectedIndex === -1) {
        toast.error("Please select a bracket to stake.")
        return
    }
    if (amount.current.value === 0 || amount.current.value === "") {
        toast.error("Please enter an amount to stake.")
        return
    }

    await stakeAmount(dispatch, userWallet, amount.current.value, bracketSelectedIndex)
}

export default function Staking() {
    const publicUrl = process.env.PUBLIC_URL
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const connected = useSelector((state) => state.auth.connected)
    const loading = useSelector((state) => state.auth.loading)
    const amount = useRef()
    const address = useSelector((state) => state.auth.address)
    const rewardsBalanace = useSelector((state) => state.auth.balance)
    const [tokensBalance, setTokensBalance] = useState(0)
    const [totalStaked, setTotalStaked] = useState(0)
    const [brackets, setBrackets] = useState([])
    const [bracketSelected, setBracketSelected] = useState({})
    const [bracketSelectedIndex, setBracketSelectedIndex] = useState(-1)
    const [amountStake, setAmountStake] = useState(0)
    const [isStaking, setIsStaking] = useState(true)
    const [canClaim, setCanClaim] = useState(false)
    const [rewards, setRewards] = useState(0)
    const [daysForClaim, setDaysForClaim] = useState(0)
    const [hasBalance, setHasBalance] = useState(false)
    const [hasMinAmountRequired, setHasMinAmountRequired] = useState(false)
    const [APY, setAPY] = useState({
        daily: 0,
        monthly: 0,
        yearly: 0
    })

    useEffect(async () => {
        if (address) {
            const enoughBalance = await hasEnoughTokenBalance(address)
            setHasBalance(enoughBalance)
            dispatch(setLoading(false))
            const bracketsList = await listBrackets()
            const tokens = await getStakeTokenBalance(address)
            const totalStaked = await getTotalStaked(address)
            setTokensBalance(tokens)
            setBrackets(bracketsList)
            setTotalStaked(totalStaked)

            const isStaking = await checkIfUserIsStaking(address)
            setIsStaking(isStaking)

            const canClaim = await checkIfCanClaim(address)
            setCanClaim(canClaim)

            const userRewards = await calculateRewards(address)
            setRewards(userRewards)

            const daysLocked = await daysUntilClaim(address)
            setDaysForClaim(daysLocked)

            const userCurrentBracket = await userBracket(address)
            if (userCurrentBracket) {
                if (userCurrentBracket["dailyRewards"]) {
                    const dailyReward = userCurrentBracket["dailyRewards"] / PRECISION
                    setAPY({
                        daily: dailyReward,
                        monthly: dailyReward * 30,
                        yearly: dailyReward * 365
                    })
                }
            }
        }
    }, [address])

    function onAmountChange(e) {
        setAmountStake(e.target.value)
        if (e.target.value >= BILLION) {
            setHasMinAmountRequired(true)
        } else {
            setHasMinAmountRequired(false)
        }
    }

    function selectBracket(e) {
        try {
            const index = e.target.value
            const bracket = brackets.find((bracket) => bracket.lockedDays === index)
            const bracketIndex = brackets.findIndex((bracket) => bracket.lockedDays === index)
            if (index == "null") {
                setAPY({
                    daily: 0,
                    monthly: 0,
                    yearly: 0
                })
                setBracketSelected({})
                setBracketSelectedIndex(null)
            } else {
                setBracketSelectedIndex(bracketIndex)
                setBracketSelected(bracket)
                if (bracket.dailyRewards) {
                    const dailyReward = bracket.dailyRewards / PRECISION
                    setAPY({
                        daily: dailyReward,
                        monthly: dailyReward * 30,
                        yearly: dailyReward * 365
                    })
                }
            }
        } catch (e) {
            console.error(e)
        }
    }

    function selectStakePercentage(a) {
        const percentage = (tokensBalance * a) / 100
        const percAmount = Math.trunc(percentage)
        amount.current.value = percAmount
        setAmountStake(amount.current.value)
        if (percAmount >= BILLION) {
            setHasMinAmountRequired(true)
        } else {
            setHasMinAmountRequired(false)
        }
    }

    function needToLogin() {
        navigate("/")
    }

    return (
        <>
            <ToastContainer />
            <Header />

            {!connected ? (
                <div className="stake-page">
                    <button className="btn" onClick={() => needToLogin()}>
                        <img alt="connect wallet icon" src={publicUrl + "image/wallet-icon.svg"} />
                        LOGIN TO ACCESS STAKING
                    </button>
                </div>
            ) : (
                <div className="stake-page">
                    {hasBalance ? (
                        <>
                            {loading && (
                                <div className="loading">
                                    <div className="loader"></div>Processing...
                                </div>
                            )}
                            <div className="stake-details">
                                <div>
                                    <ul>
                                        <li>
                                            Token Balance
                                            <br />
                                            <span>{formatBalance(tokensBalance)}</span>
                                        </li>
                                        <li>
                                            Staked
                                            <br />
                                            <span>{formatBalance(totalStaked)}</span>
                                        </li>
                                        <li>
                                            Staking Rewards
                                            <br />
                                            <span>{formatBalance(rewards)}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <ul>
                                        <li>
                                            Daily APY
                                            <br />
                                            <span>{formatAPY(APY.daily)}%</span>
                                        </li>
                                        <li>
                                            Monthly APY
                                            <br />
                                            <span>{formatAPY(APY.monthly)}%</span>
                                        </li>
                                        <li>
                                            Yearly APY
                                            <br />
                                            <span>{formatAPY(APY.yearly)}%</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            {!isStaking && !canClaim && (
                                <>
                                    <div className="stake-amount">
                                        <input
                                            placeholder="Type amount to stake"
                                            ref={amount}
                                            value={amountStake}
                                            type="number"
                                            onChange={onAmountChange}
                                        />
                                    </div>

                                    <div className="stake-percentage">
                                        <label htmlFor="p25" onClick={() => selectStakePercentage(25)}>
                                            <span>25%</span>
                                            <input id="p25" type="radio" />
                                        </label>
                                        <label htmlFor="p50" onClick={() => selectStakePercentage(50)}>
                                            50%
                                            <input id="p50" type="radio" />
                                        </label>
                                        <label htmlFor="p75" onClick={() => selectStakePercentage(75)}>
                                            75%
                                            <input id="p75" type="radio" />
                                        </label>
                                        <label htmlFor="p99" onClick={() => selectStakePercentage(100)}>
                                            99%
                                            <input id="p100" type="radio" />
                                        </label>
                                    </div>

                                    <div className="stake">
                                        <select className="stake-select" onChange={selectBracket}>
                                            <option value="null">Select stake time</option>
                                            {brackets &&
                                                brackets.map((bracket, i) => (
                                                    <option value={bracket.lockedDays} key={i}>
                                                        {bracket.lockedDays} days
                                                    </option>
                                                ))}
                                        </select>
                                        <button
                                            className="btn" 
                                            disabled={hasBalance}
                                            onClick={() => stake(dispatch, address, amount, bracketSelectedIndex)}
                                        >
                                            Stake
                                        </button>
                                    </div>
                                </>
                            )}

                            {isStaking && !canClaim && (
                                <div>
                                    <p>Rewards available {daysForClaim}</p>

                                    <div className="options mt-30">
                                        <button className="btn" onClick={() => claimOnlyRewards(dispatch, address)}>
                                            <img alt="claim icon" src={publicUrl + "image/claim-icon.svg"} />
                                            Claim Rewards
                                        </button>
                                    </div>
                                </div>
                            )}

                            {isStaking && (
                                <div className="options mt-30">
                                    <button
                                        className="btn"
                                        disabled={!canClaim}
                                        onClick={() => claimStakingRewards(dispatch, address)}
                                    >
                                        <img alt="claim icon" src={publicUrl + "image/claim-icon.svg"} />
                                        Claim
                                    </button>
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            <div className="dAppActions">
                                <p>You don't have enough PEEP$ balance to access the platform. 25 thousand is required.</p>
                            </div>
                            <div className="options mt-30">
                                <Link to="/" className="btn btn-back">
                                    <img alt="reward icon" src={publicUrl + "image/reward-icon.svg"} />
                                    Back to Rewards
                                </Link>
                            </div>
                        </>
                    )}
                </div>
            )}

            <Watermark />
            <Footer />
        </>
    )
}
