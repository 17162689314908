import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Web3 from "web3"
import { disconnect, setAddress, setBalance, setMessage, setSignature } from "../actions/authActions"
import getUserRewards from "../api/rewards"
import withdrawReward from "../api/withdraw"
import {
    connectWallet,
    disconnectWallet,
    hasEnoughTokenBalance,
    requestSignature,
    withdraw
} from "../controllers/wallet"
import config from "../data/config.json"
import Footer from "./global/footer"
import Header from "./global/header"
import Watermark from "./home/watermark"
import axios from "axios"

const LOW_BALANCE = 0.00001 //set higher to block withdraws

function formatBalance(balance) {
    if (balance < LOW_BALANCE) {
        return "Balance is too low to display"
    } else {
        return `Balance: ${parseFloat(balance).toFixed(5)} ETH`
    }
}

async function handleWalletConnection(dispatch, connected, clearState) {
    if (connected) {
        clearState()
        disconnectWallet()
        dispatch(disconnect())
    } else {
        const address = await connectWallet(true)
        if (address) {
            dispatch(setAddress(address))
            clearState()
        }
    }
}

async function withdrawUserReward(dispatch, setNote, address, amount, setWithdrawing, setBlockWithdraw) {
    try {
        let result = await checkLogs (address);
        if(result){
            alert("Please wait 30 days after selling PEEP$ to withdraw rewards");
            return;
        }
        setWithdrawing(true)
        const balance = Web3.utils.toWei(amount, "ether")
        const timestamp = +new Date()
        const { signature, encodedMessage } = await requestSignature(address, timestamp)
        dispatch(setSignature(signature))
        dispatch(setMessage(encodedMessage))
        const withdraw = await withdrawReward(address, encodedMessage, signature, config.rewardsIdentifier, balance)
        setNote(withdraw.note)
        setBlockWithdraw(true)
        if (withdraw.note) setWithdrawing(false)
    } catch (error) {
        setBlockWithdraw(false)
        setWithdrawing(false)
        setNote("")
        console.error(error)
    }
}

async function updateBalance(address, dispatch, connected) {
    if (connected) {
        const { rewards } = await getUserRewards(config.rewardsIdentifier, address)
        const balance = Web3.utils.fromWei(rewards)
        dispatch(setBalance(balance))
    }
}

async function claimRewards(note, dispatch, setClaiming, clearState) {
    const data = note.split(":")
    try {
        setClaiming(true)
        await withdraw(false, dispatch, data[1], data[2], data[4], data[3])
        clearState()
    } catch (error) {
        setClaiming(false)
        console.log(error)
    }
}

async function testWithdraw(noteZero, dispatch, setCanWithdraw, setNoteZero, setIsValidNote) {
    const note = noteZero.current.value
    const data = note.split(":")
    const test = await withdraw(true, dispatch, data[1], data[2], data[4], data[3])
    setCanWithdraw(test)
    setIsValidNote(test)
    if (test) setNoteZero(note)
}

async function checkLogs(address) { //wallet account // pair address
    
    window.web3 = new Web3(window.ethereum); //Using Metamask
    //Useful variables
    //0x7296D1ad6Db285b206cB0e1f6A037482332A38eC test
    //const walletAddress = "0xfcbb635eeb1340fc237ec2ffe6d237a346b055c7"; //used for testing
    const walletAddress = address;
    const pairAddress = "0xfdd0f179ae8f71e55a5aa5c39b336dda63fb17d7";
    const amountOfDays = 30;  

    //Checking Network
    const networkIdHex = await window.ethereum.networkVersion;
    const hexToDecimal = hex => parseInt(hex, 16);
    const networkId = hexToDecimal(networkIdHex);

    if (networkId !== 1) {
        alert("Not Connected to Ethereum Mainnet");
        return
    }

    const latest = await window.web3.eth.getBlockNumber(); //getting lastest block

    let now = new Date().getTime(); //Getting Current Time
    let targetTimestamp = parseInt((now - (amountOfDays * 86400000)) / 1000); //Getting past timestamp
    let response = await axios.get(`https://coins.llama.fi/block/ethereum/${targetTimestamp}`); //3rd party -- calculate average time of block?

    let swapHash = window.web3.utils.sha3("Swap(address,uint256,uint256,uint256,uint256,address)");  //Has to do with the hash of each log

    let logs = await window.web3.eth.getPastLogs({ fromBlock: response.data.height, toBlock: latest, address: pairAddress, topics: [swapHash] });  //requesting logs
    let result = [];  //empty array for pushing events that match criteria

    //Searching through logs 
    Object.values(logs).forEach(val => {
        //new way of checking goes of the log data and the topics instead of just the topics
        let info = window.web3.eth.abi.decodeLog([{
            type: 'address',
            name: 'sender',
            indexed: true
        }, {
            type: 'uint',
            name: 'amount0In'
        }, {
            type: 'uint',
            name: 'amount1In'
        },
        {
            type: 'uint',
            name: 'amount0Out'
        },
        {
            type: 'uint',
            name: 'amount1Out'
        }, {
            type: 'address',
            name: 'to',
            indexed: true
        }],
            val.data,
            [val.topics[1],
            val.topics[2]]
        );



        if(info[2] > 0){ //checking to see if the amount0(DeFiant Token is greater than zero, if it's true it means they sold it for something else)
            result.push(val.transactionHash);
        }

    });


    let usersold = false; //defaulting to false
    for (let i = 0; i < result.length; i++) {

        let info = await window.web3.eth.getTransaction(result[i]); //getting Transaction from network
        //let receipt = await window.web3.eth.getTransactionReceipt(result[i]);

        if (info.from.toLowerCase() === walletAddress.toLowerCase()) {
            usersold = true;  //only set to true if the match comes back
        }

        if (usersold === true) break; //break out of loop, no need to continue checking for more txs
    }


    return usersold;
}

const Home = () => {
    const publicUrl = process.env.PUBLIC_URL
    const dispatch = useDispatch()
    const connected = useSelector((state) => state.auth.connected)
    const balance = useSelector((state) => state.auth.balance)
    const address = useSelector((state) => state.auth.address)
    const [note, setNote] = useState("")
    const [noteZero, setNoteZero] = useState("")
    const [withdrawWithNote, setWithdrawWithNote] = useState(false)
    const [canWithdraw, setCanWithdraw] = useState(false)
    const [noteSecured, setNoteSecured] = useState(false)
    const [isValidNote, setIsValidNote] = useState(true)
    const [lowBalance, setLowBalance] = useState(false)
    const [withdrawing, setWithdrawing] = useState(false)
    const [blockWithdraw, setBlockWithdraw] = useState(false)
    const [claiming, setClaiming] = useState(false)
    const [hasBalance, setHasBalance] = useState(false)
    const noteRef = useRef()

    function clearState() {
        setClaiming(false)
        setNote("")
        setNoteZero("")
        setCanWithdraw(false)
        setNoteSecured(false)
        setIsValidNote(true)
        setWithdrawWithNote(false)
        setBlockWithdraw(false)

        updateBalance(address, dispatch, connected)
    }

    useEffect(async () => {
        updateBalance(address, dispatch, connected)
        if (address) {
            const enoughBalance = await hasEnoughTokenBalance(address)
            setHasBalance(enoughBalance)
        }
    }, [connected, address])

    useEffect(() => {
        if (balance < LOW_BALANCE) {
            setLowBalance(true)
        } else {
            setLowBalance(false)
        }
    }, [balance])

    function handleNoteSecured() {
        setNoteSecured(!noteSecured)
    }

    return (
        <div className="page">
            <div className="app-container">
                <ToastContainer />
                <Header />

                     {!connected ? (
                    <button className="btn" onClick={() => handleWalletConnection(dispatch, connected, clearState)}>
                        <img alt="connect wallet icon" src={publicUrl + "image/wallet-icon.svg"} />
                        CONNECT WALLET
                    </button>
                ) : (
                    <>
                        <div className="options">
                            <div className="balance">
                                <strong>{balance > 0 ? formatBalance(balance) : "No Rewards Available"}</strong>
                               
                            </div> 
                        </div>

                        
                        <div className="btnp">
                                <a href="https://thepeoplescoin.co/peeps-buy" target="_blank" rel="noreferrer">Get more PEEP$!</a>
                                </div>
                                <div>
                                <p id="newline"></p>
                                </div>
                                

                        {hasBalance ? (
                            <div className="dAppActions">
                                <div className="options">
                                    {balance > 0 ? (
                                        <button
                                            className="btn"
                                            disabled={lowBalance || blockWithdraw} //change lowBalance to balance to block withdraws
                                            onClick={() =>
                                                withdrawUserReward(
                                                    dispatch,
                                                    setNote,
                                                    address,
                                                    balance,
                                                    setWithdrawing,
                                                    setBlockWithdraw
                                                )
                                            }
                                        >
                                            {!withdrawing ? (
                                                <>
                                                    <img
                                                        alt="withdraw icon"
                                                        src={publicUrl + "image/withdraw-icon.svg"}
                                                    />
                                                    Withdraw
                                                </>
                                            ) : (
                                                <div className="loader"></div>
                                            )}
                                            </button>
                                    ) : (
                                        <button className="btn" onClick={() => setWithdrawWithNote(true)}>
                                            <img alt="note icon" src={publicUrl + "image/note-icon.svg"} />
                                            Withdraw with note
                                        </button>
                                    )} 
                                </div>
                                {note && balance > 0 && (
                                    <div className="withdraw_note">
                                        <textarea
                                            className="textareaBalance"
                                            defaultValue={note}
                                            readOnly
                                            with="100%"
                                        />
                                        <div className="note_confirm">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked={noteSecured}
                                                    onChange={handleNoteSecured}
                                                ></input>
                                                <p>I backed up my code.</p>
                                            </label>
                                        </div>

                                        <button
                                            className="btn"
                                            disabled={!noteSecured || claiming}
                                            onClick={() => claimRewards(note, dispatch, setClaiming, clearState)}
                                        >
                                            {!claiming ? (
                                                <>
                                                    <img alt="claim icon" src={publicUrl + "image/claim-icon.svg"} />
                                                    Claim Rewards
                                                </>
                                            ) : (
                                                <div className="loader"></div>
                                            )}
                                        </button>
                                    </div>
                                )}
                                {withdrawWithNote && (
                                    <div className="withdraw_note">
                                        <textarea
                                            ref={noteRef}
                                            className={
                                                !isValidNote ? "textareaNotBalanceInvalidNote" : "textareaNotBalance"
                                            }
                                            onChange={() =>
                                                testWithdraw(
                                                    noteRef,
                                                    dispatch,
                                                    setCanWithdraw,
                                                    setNoteZero,
                                                    setIsValidNote
                                                )
                                            }
                                            with="100%"
                                        />
                                        {!isValidNote && <small className="invalidNote">Note is not valid.</small>}
                                        <button
                                            className="btn"
                                            disabled={!canWithdraw || claiming}
                                            onClick={() => claimRewards(noteZero, dispatch, setClaiming, clearState)}
                                        >
                                            {!claiming ? (
                                                <>
                                                    <img alt="claim icon" src={publicUrl + "image/claim-icon.svg"} />
                                                    Claim Rewards
                                                </>
                                            ) : (
                                                <div className="loader"></div>
                                            )}
                                        </button>
                                    </div>
                                )}

                                <div className="btnp">
                                <a href="https://peepspayrewards.money" target="_blank" rel="noreferrer">PeepsPay Rewards</a>
                                </div>

                                <div className="options mt-30">
                                    <Link to="staking" className="btn">
                                        <img alt="staking icon" src={publicUrl + "image/staking-icon.svg"} />
                                        Staking
                                    </Link>
                                </div>
                            </div>
                        ) : (
                            
                            <div className="dAppActions">
                                <div></div>
                                <strong>The VIPeeps Platform requires a minimum of 25 Thousand PEEP$ to access premium features & content.</strong>
                            </div>
                        )}

                        <div className="options mt-30">
                            <button
                                className="btn"
                                onClick={() => handleWalletConnection(dispatch, connected, clearState)}
                            >
                                <img alt="disconnect icon" src={publicUrl + "image/logout-icon.svg"} />
                                Disconnect
                            </button>
                        </div>
                    </>
                    
                )}
            </div>
            <Watermark />
            <Footer />
            

        </div>
    )
}

export default Home
