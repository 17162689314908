export default function Watermark() {
    const publicUrl = process.env.PUBLIC_URL

    return (
        <div className="watermark">
            <strong>Powered by</strong>
            <a href="https://defiantplatform.com" target="_blank" rel="noreferrer">
                <img alt="watermark" src={publicUrl + "image/defiant-logo.png"} />
            </a>
</div>
    )
}
