export const ABI_STAKING = [
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "previousOwner",
                type: "address"
            },
            {
                indexed: true,
                internalType: "address",
                name: "newOwner",
                type: "address"
            }
        ],
        name: "OwnershipTransferred",
        type: "event"
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "wallet",
                type: "address"
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "amount",
                type: "uint256"
            }
        ],
        name: "UserDeposit",
        type: "event"
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "wallet",
                type: "address"
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "depositAmount",
                type: "uint256"
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "rewardsAmount",
                type: "uint256"
            }
        ],
        name: "UserWithdraw",
        type: "event"
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "id",
                type: "uint256"
            },
            {
                internalType: "uint256",
                name: "lockedDays",
                type: "uint256"
            },
            {
                internalType: "uint256",
                name: "dailyRewards",
                type: "uint256"
            }
        ],
        name: "addBracket",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function"
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256"
            }
        ],
        name: "brackets",
        outputs: [
            {
                internalType: "uint256",
                name: "lockedDays",
                type: "uint256"
            },
            {
                internalType: "uint256",
                name: "dailyRewards",
                type: "uint256"
            },
            {
                internalType: "bool",
                name: "enabled",
                type: "bool"
            }
        ],
        stateMutability: "view",
        type: "function"
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "wallet",
                type: "address"
            }
        ],
        name: "calculateRewards",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256"
            }
        ],
        stateMutability: "view",
        type: "function"
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "tokenAmount",
                type: "uint256"
            },
            {
                internalType: "uint256",
                name: "bracket",
                type: "uint256"
            }
        ],
        name: "deposit",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function"
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256"
            }
        ],
        name: "depositAddresses",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address"
            }
        ],
        stateMutability: "view",
        type: "function"
    },
    {
        inputs: [],
        name: "depositToken",
        outputs: [
            {
                internalType: "contract IERC20",
                name: "",
                type: "address"
            }
        ],
        stateMutability: "view",
        type: "function"
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address"
            }
        ],
        name: "deposits",
        outputs: [
            {
                components: [
                    {
                        internalType: "uint256",
                        name: "amount",
                        type: "uint256"
                    },
                    {
                        internalType: "uint256",
                        name: "timestamp",
                        type: "uint256"
                    }
                ],
                internalType: "struct StakingContract.DepositInfo",
                name: "info",
                type: "tuple"
            },
            {
                components: [
                    {
                        internalType: "uint256",
                        name: "lockedDays",
                        type: "uint256"
                    },
                    {
                        internalType: "uint256",
                        name: "dailyRewards",
                        type: "uint256"
                    },
                    {
                        internalType: "bool",
                        name: "enabled",
                        type: "bool"
                    }
                ],
                internalType: "struct StakingContract.Bracket",
                name: "bracket",
                type: "tuple"
            },
            {
                internalType: "bool",
                name: "active",
                type: "bool"
            }
        ],
        stateMutability: "view",
        type: "function"
    },
    {
        inputs: [],
        name: "owner",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address"
            }
        ],
        stateMutability: "view",
        type: "function"
    },
    {
        inputs: [],
        name: "renounceOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function"
    },
    {
        inputs: [],
        name: "rescueTokens",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function"
    },
    {
        inputs: [],
        name: "rewardsToken",
        outputs: [
            {
                internalType: "contract IERC20",
                name: "",
                type: "address"
            }
        ],
        stateMutability: "view",
        type: "function"
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "tokenAddress",
                type: "address"
            }
        ],
        name: "setDepositToken",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function"
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "tokenAddress",
                type: "address"
            }
        ],
        name: "setRewardsToken",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function"
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "newOwner",
                type: "address"
            }
        ],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function"
    },
    {
        inputs: [],
        name: "withdraw",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function"
    }
]
