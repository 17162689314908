import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { applyMiddleware, createStore } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import { persistReducer, persistStore } from "redux-persist"
import storage from "redux-persist/lib/storage"
import createSagaMiddleware from "redux-saga"
import Home from "./components/home"
import "./components/main-layout.css"
import Staking from "./components/staking"
import config from "./data/config.json"
import "./index.css"
import combinedReducer from "./reducers"
import reportWebVitals from "./reportWebVitals"

const persistConfig = {
    key: "root",
    storage
}
const persistedReducer = persistReducer(persistConfig, combinedReducer)

const sagaMiddleware = createSagaMiddleware()

const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)))

persistStore(store)

const BACKGROUND_COLOR = config.backgroundColor

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <div className="Body" style={{ backgroundColor: `${BACKGROUND_COLOR}` }}>
                <div className="App">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/staking" element={<Staking />} />
                        <Route path="*" element={<Home />} />
                    </Routes>
                </div>
            </div>
        </BrowserRouter>
    </Provider>,
    document.getElementById("root")
)

reportWebVitals()
