import axios from "axios"
import config from "../data/config.json"

export default async function withdrawReward(address, message, signature, identifier, amount) {
    const payload = {
        address,
        message,
        signature,
        identifier,
        amount
    }
    const response = await axios.post(`${config.apiUrl}/withdraw`, payload)
    return response.data
}
